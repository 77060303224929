import React from "react"
import { graphql } from "gatsby"

import { LiteraturWettbewerbTempl } from "../pageTemplates/literaturwettbewerb"

const LiteraturWettbewerb = ({ data, location }) => (
  <LiteraturWettbewerbTempl data={data} location={location} />
)

export const query = graphql`
  query {
    strapi {
      literaturwettbewerb {
        meta_en {
          title
          description
        }
      }
      competitionYears(sort: "year:desc") {
        year
        competitions(sort: "place:asc") {
          Nachname
          Vorname
          place
          alter
          images {
            url
            caption
            alternativeText
            imageFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          place
          text
          title
        }
        cover {
          url
          caption
          alternativeText
          imageFile {
            childImageSharp {
              fluid {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default LiteraturWettbewerb
